@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
    url(../fonts/SF\ Pro\ Display/SFPRODISPLAYREGULAR.OTF) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
    url(../fonts/SF\ Pro\ Display/SFPRODISPLAYMEDIUM.OTF) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
    url(../fonts/SF\ Pro\ Display/SFPRODISPLAYBOLD.OTF) format("opentype");
  font-weight: 600;
}

#componentFour {
  background-image: url("../img/home/ComponentFour/2.png");
  background-size: 100% 100% !important;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 634px !important;
  overflow: hidden;
}

.HomeComponentThree {
  background-image: url("../img/home/ComponentThree/2.png");
  background-position: center;
  background-repeat: no-repeat;
  height: auto !important;
  /* background-size: 100% 100% !important; */
  /* width: 100%; */
}

#HomeSignInComponent {
  background-image: url("../img/home/ComponentEight/2.png") !important;
  background-position: center top !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  height: auto !important;
}

#AboutUsMissionCard {
  background-image: url("../img/About\ Us/1.png") !important;
  background-position: center top !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  height: auto !important;
}

#SupportArticlesBox {
  background-image: url("../img/Support/1.png") !important;
  background-position: center top !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  height: auto !important;
}

.SignUpRightComponent {
  background-position: center top !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  height: auto !important;
}

.LoginRightComponent {
  background-position: center top !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  height: auto !important;
}

.main {
  overflow: hidden !important;
}

/* carousel properties */
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  width: 22px !important;

}

.carousel .control-dots {
  margin: -3px 0px !important;
}

/* .componentOneCarousel .carousel .control-dots{
  margin: -4px 0px!important;
} */


/* List Items Drawer */
.list-box {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  height: 83vh;

}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.modal_input_image label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #110c2c !important;
  cursor: pointer;
  border-radius: 40px;
}

.modal_input_image input {
  display: none;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15ch;
}

/* .kk .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiMenu-paper .MuiPopover-paper .MuiMenu-paper .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  width: 100% !important;
} */

.kk .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  width: 100% !important;
}